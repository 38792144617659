import React, { useEffect, useState } from 'react';
import {
  Table,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';

import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';

import DotsHorizontalIcon from 'mdi-react/DotsHorizontalIcon';
import ArrowUpIcon from 'mdi-react/ArrowUpIcon';
import ArrowDownIcon from 'mdi-react/ArrowDownIcon';
import Api from '../../../util/api';

import t, { partial } from '../../../util/translation/translation';

import { getBaseDate } from '../../../util/date-and-time';
import Loading from '../../../shared/components/custom/Loading';
import DeleteModal from '../../../shared/components/custom/DeleteModal';
import useModal from '../../../shared/hooks/useModal';
import Pagination from '../../../shared/components/pagination/Pagination';

const s = partial('shared');

const DealsList = () => {
  const [loading, setLoading] = useState(false);
  const [deals, setDeals] = useState([]);
  const [handleModal, visible, deleteID] = useModal();
  const [pagination, setPagination] = useState({
    total: 0,
    currentPage: 1,
    perPage: 10,
  });
  const [order, setOrder] = useState({
    orderBy: 'madeAt',
    desc: true,
  });

  const getDeals = async (currentPage = 1, pageSize = 10, orderObject = {
    orderBy: 'madeAt',
    desc: true,
  }) => {
    setLoading(true);
    try {
      const resDeals = await Api.companies.getDeals({ page: currentPage, take: pageSize }, { orderBy: orderObject.orderBy, direction: orderObject.desc ? 'DESC' : 'ASC' });
      setPagination(old => ({ ...old, total: resDeals.count }));
      setDeals(resDeals.data);
      setLoading(false);
    } catch (err) {
      toast.error(JSON.stringify(err));
      setLoading(false);
    }
  };
  const deleteDeal = async () => {
    setLoading(true);
    try {
      await Api.companies.deleteDeal(deleteID);
      toast.success(t('CompanyListPage.dealRemoved'));
    } catch (err) {
      toast.success(s('somethingWentWrong'));
    } finally {
      handleModal();
      getDeals(pagination.currentPage, pagination.perPage, order);
    }
  };
  useEffect(() => {
    getDeals();
  }, []);

  const onChangePage = (pager) => {
    if (pager.pageSize !== pagination.perPage || pager.currentPage !== pagination.currentPage) {
      setPagination(old => ({
        ...old,
        currentPage: pager.currentPage,
        perPage: pager.pageSize,
      }));
      getDeals(pager.currentPage, pager.pageSize, order);
    }
  };
  const handleSort = (incommingOrder) => {
    const newOrder = {
      orderBy: incommingOrder,
      desc: incommingOrder === order.orderBy ? !order.desc : true,
    };
    setOrder(newOrder);
    setPagination({
      total: 0,
      currentPage: 1,
      perPage: 10,
    });
    getDeals(1, 10, newOrder);
  };

  const listDeals = () => {
    return deals.map((deal, i) => (
      <tr style={{ background: (i) % 2 ? '#fff' : '#F9FAFB' }} key={`deal-${deal.id}`}>
        <td><Link to={`/companies/edit/${deal.createdByCompanyId}?tab=1`}>{deal?.createdByCompany?.name || '-'}</Link></td>
        <td><Link to={`/companies/edit/${deal.subjectCompanyId}?tab=1`}>{deal?.subjectCompany?.name || '-'}</Link></td>
        <td>{deal.value}</td>
        <td>{deal.transactionType === 'BOUGHT' ? t('KioskPage.bought') : s('sold')}</td>
        <td>{getBaseDate(deal.madeAt)}</td>
        <td>
          <UncontrolledDropdown className="dashboard__table-more">
            <DropdownToggle>
              <DotsHorizontalIcon />
            </DropdownToggle>
            <DropdownMenu className="dropdown__menu">
              <DropdownItem onClick={() => handleModal(deal.id)} className="danger">{s('delete')}</DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </td>
      </tr>
    ));
  };
  const renderSort = (cellOrder) => {
    if (order.orderBy !== cellOrder) {
      return undefined;
    }
    return order.desc ?
      <ArrowDownIcon size={16} />
      :
      <ArrowUpIcon size={16} />;
  };

  return (
    <>
      <DeleteModal
        visible={visible}
        handleModal={handleModal}
        type={t('CompanyListPage.deal').toLowerCase()}
        modalAction={deleteDeal}
      />
      <Loading loading={loading} />
      <div style={{ display: 'flex', paddingBottom: 5, background: '#fff', borderRadius: 6, boxShadow: '0px 2px 8px 0px rgba(0,0,0,0.08)' }}>
        <Table responsive striped>
          <thead>
            <tr>
              <th style={{ color: '#4D576A' }} className="sortable" onClick={() => handleSort('createdCompanyName')}>
                <div className="flex alignItems-center">{t('DashboardPage.createdBy')} {renderSort('createdCompanyName')}</div>
              </th>
              <th style={{ color: '#4D576A' }} className="sortable" onClick={() => handleSort('subjectCompanyName')}>
                <div className="flex alignItems-center">{t('CompanyListPage.subjectCompany')} {renderSort('subjectCompanyName')}</div>
              </th>
              <th style={{ color: '#4D576A' }} className="sortable" onClick={() => handleSort('value')}>
                <div className="flex alignItems-center">{t('LotteryForm.value')} {renderSort('value')}</div>
              </th>
              <th style={{ color: '#4D576A' }} className="sortable" onClick={() => handleSort('transactionType')}>
                <div className="flex alignItems-center">{s('type')} {renderSort('transactionType')}</div>
              </th>
              <th style={{ color: '#4D576A' }} className="sortable" onClick={() => handleSort('madeAt')}>
                <div className="flex alignItems-center">{s('created')} {renderSort('madeAt')}</div>
              </th>
              <th />
            </tr>
          </thead>
          <tbody>{listDeals()}</tbody>
        </Table>
      </div>
      <Pagination
        items={pagination.total}
        onChangePage={onChangePage}
        initialPage={pagination.currentPage}
      />
    </>
  );
};


export default DealsList;
